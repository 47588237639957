import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
// import i18n (needs to be bundled ;))
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {CssBaseline} from '@mui/material'
import './i18n';
import store from './app/store';
import { Provider } from 'react-redux';

const navyBlueTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#34349c',
      contrastText: 'rgba(255,255,255,0.87)',
      light: '#c8caec',
      lightest:"#F4FBFF"
    },
    secondary: {
      main: '#3b73a8',
    },
    complementary:{
      main:"#a8a63b",
      light:"#fafce9"
    }
  },
  });

const violetTheme = createTheme({
palette: {
  type: 'light',
  primary: {
    main: '#853092',
    light:'#d2a0dc',
    contrastText: 'rgba(255,255,255,0.87)',
  },
  secondary: {
    main: '#f50057',
  },
  complement: {
    main: '#DFBE46',
  },
},
});

const dullGreenTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#adc178',
      light: '#ddaee6',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#fbffed',
    },
  },
});

// console.log = function () {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={navyBlueTheme}>
      <CssBaseline enableColorScheme />
      
    <App />
    
    </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
