import * as React from "react";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";

import {Box, Toolbar, Button, Typography, AppBar, styled, Link, Tooltip, Select, MenuItem} from "@mui/material";
import Logo from "../assets/dempulse_white_crop.svg";
import { useLocation, useNavigate  } from "react-router-dom";
import '../css/hideScrollBar.css';
import { useTranslation } from 'react-i18next';


const settings = ["Profile", "Account", "Dashboard", "Logout"];

const StyledButton = styled(Button)(`
  text-transform: none;
`);

const RespTopBar = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const updateLang = (event) => {
      i18n.changeLanguage(event.target.value);
    };

    const pages = [
        {lbl:`${t('key_new_poll')}`, pgPath:"/signin"},
        {lbl:`${t('key_about')}`, pgPath:"/faq"},
        {lbl:`${t('key_tos')}`, pgPath:"/tos"},
       ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const takeActionNavMenu = (pageName) => {
    setAnchorElNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box id="mdLogoBox" sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
        <Tooltip title={"t('dp_tagline')"}>
        <Link href="/survey"><Box  component="img" sx={{ height: 60, padding: "10px 0px 10px 10px",mr: 5 }} alt="DemPulse"
            src={Logo} /></Link></Tooltip>
        <Typography variant="h6" textAlign="center" component="div" sx={{ fontWeight:"bold", flexGrow: 1 }}>
          </Typography>
           </Box>
          <Box id="hamburgerBox" sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit" >
              <MenuIcon />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
              keepMounted transformOrigin={{ vertical: "top", horizontal: "left", }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} sx={{ display: { xs: "block", md: "none" },  }} >
              {pages.map((page) => (
                <MenuItem key={page} onClick={()=>{navigate(page.pgPath)}}>
                  <Typography textAlign="center">{page.lbl}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box id="xsLogoBox" sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, mr: 1 }}>
        <Tooltip title={"t('dp_tagline')"}>
        <Link href="/survey"><Box  component="img" sx={{ height: 60, padding: "10px 0px 10px 10px",mr: 5 }} alt="DemPulse"
            src={Logo} /></Link></Tooltip>
        <Typography variant="h6" textAlign="center" component="div" sx={{ fontWeight:"bold", flexGrow: 1 }}>
          </Typography>
           </Box>
          <Box id="mdNavBar" justifyItems="flex-end" justifyContent="right" sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {/* <Select
                autoWidth
                id="lang_select_dropdown"
                defaultValue={'en'}
                value={i18n.resolvedLanguage}
                label="Language"
                onChange={updateLang}
                sx={{color:"white", fontSize:"18px", border:0, borderColor:"#adc178"}}
              >
                <MenuItem value={'en'} >English</MenuItem>
                <MenuItem value={'es'}>Espanol</MenuItem>
                <MenuItem value={'ta'}>தமிழ்</MenuItem>
                <MenuItem value={'hi'}>हिन्दी</MenuItem>
                <MenuItem value={'gu'}>ગુજરાતી</MenuItem>
                <MenuItem value={'bn'}>বাংলা</MenuItem>
                <MenuItem value={'kn'}>ಕನ್ನಡ</MenuItem>
                <MenuItem value={'te'}>తెలుగు</MenuItem>
                <MenuItem value={'ml'}>മലയാളം</MenuItem>
              </Select> */}
            {/* </FormControl> */}
            {pages.map((page,pg_index) =>{
                return (
                <StyledButton key={page.lbl} component={Link} href={page.pgPath}color="inherit"> {page.lbl}
                </StyledButton>
            )}
            )}
            {/* {location.pathname != '/signin' && <StyledButton component={Link} href="/signin" color="inherit"> {t('key_new_poll')}</StyledButton>}
            { location.pathname != '/faq' && <StyledButton component={Link} href="/faq" color="inherit"> {t('key_about')}</StyledButton>}
            {location.pathname != '/tos' && <StyledButton component={Link} href="/tos" color="inherit"> {t('key_tos')}</StyledButton>} */}
...  
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default RespTopBar;
