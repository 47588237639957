import React, {Suspense, lazy} from "react";
import { Box } from "@mui/material";
import "./styles.css";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import BusySpinner from './components/BusySpinner.js';
import { useSelector, useDispatch } from 'react-redux';

// import DemSrvy from "./components/DemSrvy.js";
// import Signup from "./components/SignUp.js";
// import NoSurvey from "./components/NoSurvey";
// import ConfirmEmail from './components/ConfirmEmail';
// import SignIn from "./components/SignIn";
// import UserHome from "./components/UserHome";
// import FAQPage from "./components/FAQPage";
// import Tos from "./components/Tos";

import RespTopBar from "./components/RespTopBar";
const DemSrvy = React.lazy(() => import('./components/DemSrvy'));
const NoSurvey = React.lazy(() => import('./components/NoSurvey'));
const ConfirmEmail = React.lazy(() => import('./components/ConfirmEmail'));
const SignIn = React.lazy(() => import('./components/SignIn'));
const UserHome = React.lazy(() => import('./components/UserHome'));
const FAQPage = React.lazy(() => import('./components/FAQPage'));
const Tos = React.lazy(() => import('./components/Tos'));
const Signup = React.lazy(() => import('./components/SignUp'));
const theme = createTheme();

function App() {
  // const [selectedCntry, setSelectedCntry] = React.useState({
  //   "countryName": "American Samoa",
  //   "countryShortCode": "AS"});
  // const clientjs = new ClientJS();

  // useEffect(() => {
  //   console.log("geoloc access has changed", geoLocAccess);
  // }, [geoLocAccess]);
//   setLcl_cki({
//     ...lcl_cki, 
//     usr_id:localStorage.getItem('usr_id'),
//     loc:localStorage.getItem('loc'),
//     code:localStorage.getItem('code'),
//     os:clientjs.getOS(),
//     mobile:clientjs.isMobile(),
//     fprnt:clientjs.getFingerprint(),
//     // bfprnt:getCurrentBrowserFingerPrint()
// })
  const loading = useSelector((state) => state.rx_loading.value);

  return (
    <Box sx={{ height: "100vh", width: "100%" }}>
          { loading > 0 && <BusySpinner />}
       <BrowserRouter>
      {/* <TopBar /> */}
      <Suspense fallback={<BusySpinner />}>
        <Routes>
        <Route path="/" exact element={<Navigate replace to="/survey" />} />
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/signin" exact element={<SignIn />} />
        <Route path="/user/*" exact element={
          <ThemeProvider theme={theme}> 
            <CssBaseline enableColorScheme />
            <UserHome />
          </ThemeProvider>} />
        <Route path="/faq" exact element={<FAQPage />} />
        <Route path="/confirmemail" exact element={<ConfirmEmail />} />
        <Route path="/nosurvey" exact element={<NoSurvey />} />
        <Route path="/survey" exact element={<DemSrvy/>} />
        <Route path="/tos" exact element={<Tos/>} />
        <Route path="/test" exact element={<RespTopBar/>} />
        <Route path="/*" element={<Navigate replace to="/survey" />} />
        </Routes>
        </Suspense>
      </BrowserRouter>
    </Box>
  );
}

export default App;
