import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
    name: "rx_loading",
    initialState: {
        value: false,
    },
    reducers: {
        showLoad: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = true;
        },
        hideLoad: (state) => {
            state.value = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { showLoad, hideLoad } = loadingSlice.actions;

export default loadingSlice.reducer;